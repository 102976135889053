import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';

const DashboardHeader = () => {


	const userDataCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('userDAta='));

let userData ={};
if (userDataCookie) {
    userData = JSON.parse(userDataCookie.split('=')[1]);
} else {
}
 
	return (
		<Header>
			<HeaderLeft>
				<Search />
			</HeaderLeft>
			<CommonHeaderRight afterChildren={<CommonHeaderChat userData={userData} />} />
		</Header>
	);
};

export default DashboardHeader;
