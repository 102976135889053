import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createFile, getAllFile } from '../../Api/FileApi/File';
import { createFolder, folderMultipleRemove, folderRemove, getAllFolder, getFolderByid, starredFolder } from '../../Api/FolderSideBar/Folder';
const initialState: any = {
	Folder: [],
	currentFolder:[],
	loading: false,
	error: null,
};
// create File
export const createFolderSlice = createAsyncThunk('Folder/createFolder', async (data: any) => {
	try {
		const response = await createFolder(data);

		return response;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});

//   get all
export const getallFolderSlice = createAsyncThunk('Folder/getallFolderSlice', async (data: any) => {
	try {
		const response = await getAllFolder(data);
		return response.data.data;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});
// ?getby id
export const getallFolderByidSlice = createAsyncThunk('Folder/getallFolderByidSlice', async (id: any) => {
	try {
		const response = await getFolderByid(id);
		return response.data.data;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});

// remove 
export const removeFolder = createAsyncThunk(`Folder/removeFolder`,async(data:any)=>{
 try {
	
	const respone = await folderRemove(data)
 console.log(respone)
	return respone
 } catch (error) {
	console.error('Error creating user:', error);
	throw error;
 }
})

export const removeMulFolder = createAsyncThunk(`Folder/removeMulFolder`,async(data:any)=>{
	try {
	   
	   const respone = await folderMultipleRemove(data)
	console.log(respone,"mul")
	   return respone
	} catch (error) {
	   console.error('Error creating user:', error);
	   throw error;
	}
   })

   export const starredFolderSlice = createAsyncThunk(`Folder/starredFolderSlice`,async(data:any)=>{
	try {
	   
	   const respone = await starredFolder(data)
	console.log(respone,"mul")
	   return respone
	} catch (error) {
	   console.error('Error creating user:', error);
	   throw error;
	}
   })
const FolderSlice = createSlice({
	name: 'FolderSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createFolderSlice.fulfilled, (state, action: any) => {
				console.log(action.payload);

				state.Folder.push(action.payload)
				state.loading = false;
			})
			.addCase(createFolderSlice.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(createFolderSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = 'error';
			})

            // get all
            .addCase(getallFolderSlice.fulfilled, (state, action: any) => {
				console.log(action.payload);

				state.Folder = action.payload;
				state.loading = false;
			})
			.addCase(getallFolderSlice.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getallFolderSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = 'error';
			})

			// get by id
			.addCase(getallFolderByidSlice.fulfilled, (state, action: any) => {
				console.log(action.payload);

				state.currentFolder = action.payload;
				state.loading = false;
			})
			.addCase(getallFolderByidSlice.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getallFolderByidSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = 'error';
			})
           
			// remove

			.addCase(removeFolder.fulfilled, (state, action: any) => {
				console.log(action.payload);

				// state.currentFolder = action.payload;
				state.loading = false;
			})
			.addCase(removeFolder.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(removeFolder.rejected, (state, action) => {
				state.loading = false;
				state.error = 'error';
			});
	},
});

export default FolderSlice.reducer;
