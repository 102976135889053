import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllTrash, restoreTrash } from "../../Api/Trash/Trash";
import { getAllStarred } from "../../Api/Starred/Starred";
const initialState :any={
    Starred:[],
    loading:false,
    error:null
}
// get all trash
export const getallStarredSlice = createAsyncThunk('Starred/getallStarredSlice', async (data: any) => {
	try {
		const response = await getAllStarred(data);
		return response;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});


const StarredSlice = createSlice({
    name:'starredSlice',
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(getallStarredSlice.fulfilled,(state,action)=>{
           state.Starred= action.payload
           state.loading = false;

        })
        .addCase(getallStarredSlice.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getallStarredSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = 'error';
        });
    }
  
})

export default StarredSlice.reducer;
