import axios from "axios"
import {BaseURl} from './../../utils'  


 const createLogin=async(data:any)=>{
    try {
        const resoponse = await axios.post(`${BaseURl}admins/login`,data)
        console.log(resoponse)
       
        return resoponse
    } catch (error) {
      console.log(error)
      throw error;    
    }
}


export const forgotPassword = async(data:any)=>{
  try {
    const resoponse = await axios.post(`${BaseURl}User/forgot-password`,data)
        console.log(resoponse)
        return resoponse
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const verifyOTP = async(data:any)=>{
  try {
    
    const resoponse = await axios.post(`${BaseURl}User/verifyOTP`,data?.values)
        console.log(resoponse)
        return resoponse
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updatePassword = async(data:any)=>{
  try {
    
    const resoponse = await axios.post(`${BaseURl}/User/resetPassword`,data)
        console.log(resoponse)
        return resoponse
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export default createLogin