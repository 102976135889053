export const BaseURl = 'https://liveapi.meander.software/'
export const ModeId = '65c9e836ef719d3c44940a99'


const userDataCookie = document.cookie
.split('; ')
.find(row => row.startsWith('userDAta='));

export let userData ={};
if (userDataCookie) {
userData = JSON.parse(userDataCookie.split('=')[1]);
} else {
}


export  const  getLocalData =()=>{
    return JSON.parse(localStorage.getItem('Filez_authUsername')as any)
}