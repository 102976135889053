import axios from 'axios';
import { BaseURl } from '../../../utils';

export const createFileName = async (data: any) => {
	console.log(data, 'new');

	// Assuming 'avatar' is the key for the file in the FormData
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json', // Assuming data is JSON
		},
		body: JSON.stringify(data),
	};

	try {
		const response = await fetch(`https://liveapi.meander.software/file/create`, options);
		const daa = await response.json();
		return daa.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
export const createFile = async (data: any) => {
	const { file, id, folderId } = data;

	const formData = new FormData();
	formData.append('file', file); // Assuming 'avatar' is the key for the file in the FormData
	const options = {
		method: 'PUT',

		body: formData,
	};

	try {
		const response = await fetch(`${BaseURl}/file/UploadFile/${id}/${folderId}`, options);
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
// export const createFile = async (data:any) => {
//     const { file,id } = data;

//     const formData = new FormData();
//     formData.append('file', file); // Assuming 'avatar' is the key for the file in the FormData
//     const options = {
//       method:'PUT',

//       body:formData
//     };

//     try {
//       const response = await fetch(`${BaseURl}/file/UploadFile/${id}`, options);
//       return response;
//     } catch (error) {
//       console.error(error);
//       throw error;
//     }
//   };

//   get all Files

export const getAllFile = async (modeId: any) => {
	try {
		const resoponse = await axios.get(
			`${BaseURl}file/getall/${modeId}?page_no=1&page_size=100`,
		);
		return resoponse;
	} catch (error) {
		console.log(error);
		throw error;
	}
};


// multipleremove
export const  fileMultipleRemove =async(ids:any)=>{
    const data={
      "fileIds":ids
    }
    try {
      const response:any = await axios.post(`${BaseURl}file/delete-multiple`,data)
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  }