import { configureStore } from "@reduxjs/toolkit";
import UserManagement_slice from "./redux/Slice/UserManagement_slice";
import SubscriptionPlans_Slice from "./redux/Slice/SubscriptionPlans_Slice";
import FaqSlice from "./redux/Slice/FaqSlice";
import TermsAndConditionsSlice from './redux/Slice/TermAndConditionSlice'
import PrivecyPolicysSlice from "./redux/Slice/PrivecyPolicysSlice";
import AuthSlice from "./redux/Slice/AuthSlice";
import FolderSlice from "./redux/Slice/FolderSidebar/FolderSideBar";
import FileSlice from "./redux/Slice/FileSlice.tsx/FileSlice";
import Trash from "./redux/Slice/Trash/Trash";
import StarredSlice from "./redux/Slice/Starred/Starred";


 


 const store = configureStore({

    reducer: {
        auth:AuthSlice,
        user:UserManagement_slice,
        subscription:SubscriptionPlans_Slice,
        faq:FaqSlice,
        TermsAndConditions:TermsAndConditionsSlice,
        PrivecyPolicys:PrivecyPolicysSlice,
        Folder: FolderSlice,
        File:FileSlice,
        trash :Trash,
        starred:StarredSlice
    },

})
export default store