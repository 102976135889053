import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createFile, createFileName, fileMultipleRemove, getAllFile } from '../../Api/FileApi/File';
const initialState: any = {
	File: [],
	loading: false,
	error: null,
};
// create File
export const createFlie = createAsyncThunk('File/createFlie', async (data: any) => {
	try {
		const response = await createFileName(data);

		return response;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});

export const createFlieSlice = createAsyncThunk('File/createFlieSlice', async (data: any) => {
	try {
		const response = await createFile(data);

		return response;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});
//   get all
export const getallFileSlice = createAsyncThunk('File/getallFileSlice', async (data: any) => {
	try {
		const response = await getAllFile(data);
		return response.data.data;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});
// multiple
export const removeMulFile = createAsyncThunk(`File/removeMulFile`,async(data:any)=>{
	try {
	   
	   const respone = await fileMultipleRemove(data)
	console.log(respone,"mul")
	   return respone
	} catch (error) {
	   console.error('Error creating user:', error);
	   throw error;
	}
   })
const FileSlice = createSlice({
	name: 'FileSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createFlie.fulfilled, (state, action: any) => {
				console.log(action.payload);

				// state.File = action.payload;
				state.loading = false;
			})
			.addCase(createFlie.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(createFlie.rejected, (state, action) => {
				state.loading = false;
				state.error = 'error';
			})

            // get all
            .addCase(getallFileSlice.fulfilled, (state, action: any) => {
				console.log(action.payload);

				state.File = action.payload;
				state.loading = false;
			})
			.addCase(getallFileSlice.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getallFileSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = 'error';
			});
	},
});

export default FileSlice.reducer;
