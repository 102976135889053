export type TTabs = 'ListAlt' | 'Folder' ;
export const TABS: { [key: string]: TTabs } = {
	ListAlt: 'ListAlt',
	FOLDER: 'Folder',
};

export const days =['Today','Last 7 days','Last 30 days']

export const peoples = [
    'dummy1@gmail.com',
    'dummy2@gmail.com',
    'dummy3@gmail.com',
    'dummy4@gmail.com',
    'dummy5@gmail.com'
  ];

  export const AddOption = [
    // { name: "New folder", iconName: "CreateNewFolder", color: "primary", bootstrapName: "folder-plus" },
    { name: "File upload", iconName: "UploadFile", color: "success", bootstrapName: "cloud-upload" },
    // { name: "Folder upload", iconName: "CreateNewFolder", color: "primary", bootstrapName: "folder-plus" },
    // { name: "Google Docs", iconName: "UploadFile", color: "danger", bootstrapName: "file-word" },
    // { name: "Google Sheets", iconName: "UploadFile", color: "success", bootstrapName: "file-excel" },
    // { name: "Google Slides", iconName: "UploadFile", color: "warning", bootstrapName: "file-powerpoint" },
    // { name: "Google Forms", iconName: "UploadFile", color: "warning", bootstrapName: "file-text" },
    // { name: "More", iconName: "UploadFile", color: "secondary", bootstrapName: "ellipsis" }
];
