import axios from "axios";
import { BaseURl } from "../../../utils";

export const createFolder = async (data:any) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Assuming data is JSON
      },
      body: JSON.stringify(data)
    };
  
    try {
      const response:any = await fetch(`${BaseURl}folders/create`, options);
      const dataa = await response.json()
      return dataa.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
//   get all folder
export const getAllFolder=async(modeId:any)=>{
   
    try {
        const resoponse = await axios.get(`${BaseURl}folders/getall/${modeId}?page_no=1&page_size=100`)
        console.log(resoponse)
        return resoponse
    } catch (error) {
      console.log(error) 
      throw error;   
    }
  }

  // get by id
  export const getFolderByid=async(id:any)=>{
   
    try {
        const resoponse = await axios.get(`${BaseURl}/folders/getById/${id}`)
        console.log(resoponse)
        return resoponse
    } catch (error) {
      console.log(error) 
      throw error;   
    }
  }
  // create file
  export const createFileName = async (data:any) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Assuming data is JSON
      },
      body: JSON.stringify(data)
    };
  
    try {
      const response:any = await fetch(`${BaseURl}folders/create`, options);
      const daa = await response.json()
      return daa.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };


  // folder remove

  export const  folderRemove =async(data:any)=>{
    const {id,isTrue}=data
    try {
      const response:any = await axios.delete(`${BaseURl}${isTrue ? "folders/folderRemove/" : "file/fileRemove/"}${id}`)
      console.log( response,"dfd",response.json())
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // multiple detele
  export const  folderMultipleRemove =async(ids:any)=>{
    const data={
      "folderIds":ids
    }
    try {
      const response:any = await axios.post(`${BaseURl}folders/delete-multiple`,data)
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // Strred
  export const starredFolder =async(data:any)=>{
    const {isStar,id}=data
    try {
      const response = await axios.put(`${BaseURl}folders/${isStar ?"folderunmarkedstarred":"foldermarkedstarred"}/${id}`)
      return response 
    } catch (error) {
      console.error(error);
      throw error;
    }
  }