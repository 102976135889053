import React, { useEffect, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';

const CommonHeaderChat = ({ userData }: any) => {
	const [state, setState] = useState<boolean>(false);
	const [msgCount, setMsgCount] = useState<number>(0);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const SignOut = () => {
		document.cookie = 'userDAta=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		localStorage.removeItem('Filez_authUsername');
		window.location.reload();
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			setMsgCount(1);
			showNotification(
				<span className='d-flex align-items-center'>
					<Avatar
						srcSet={USERS.CHLOE.srcSet}
						src={USERS.CHLOE.src}
						size={36}
						color={USERS.CHLOE.color}
						className='me-3'
					/>
					<span>{userData.Name} sent a message.</span>
				</span>,
				<div onClick={() => setState(!state)} role='presentation'>
					<p>I think it's really starting to shine.</p>
				</div>,
			);
		}, 30000);
		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setMsgCount(0);
	}, [state]);

	return (
		<>
			{' '}
			<div
				className='col d-flex align-items-center cursor-pointer'
				onClick={() => setState(!state)}
				role='presentation'>
				<div className='me-3'>
					<div className='text-end'>
						<div className='fw-bold fs-6 mb-0'>{userData.Name}</div>
						<div className='text-muted'>
							<small>User</small>
						</div>
					</div>
				</div>
				<Dropdown>
					<DropdownToggle hasIcon={false}>
						<Button>
							<div className='position-relative'>
								<Avatar
									srcSet={USERS.CHLOE.srcSet}
									src={USERS.CHLOE.src}
									size={48}
									color={USERS.CHLOE.color}
								/>
								{!!msgCount && (
									<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
										{msgCount}{' '}
										<span className='visually-hidden'>unread messages</span>
									</span>
								)}
								<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
									<span className='visually-hidden'>Online user</span>
								</span>
							</div>
						</Button>
					</DropdownToggle>
					<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
						<div className='row g-0'>
							<div
								className={classNames(
									'col-12',
									'p-4',
									'd-flex justify-content-center',
									'fw-bold fs-5',
									'text-info',
									'border-bottom border-info',
									{
										'bg-l25-info': !darkModeStatus,
										'bg-lo25-info': darkModeStatus,
									},
								)}>
								{userData?.email}
							</div>
							<div
								className={classNames(
									'col-12 py-4 transition-base cursor-pointer bg-light-hover ',
									'border-end border-bottom',
									{ 'border-dark': darkModeStatus },
								)}>
								<div className='text-center position-relative py-2 '>
									<Avatar
										className='border border-dark'
										srcSet={USERS.CHLOE.srcSet}
										src={USERS.CHLOE.src}
										size={78}
										color={USERS.CHLOE.color}
									/>

									<span className='position-absolute top-85 start-65 translate-middle'>
										<Icon icon='EditNote' size='2x' className='me-1' />
									</span>
								</div>

								<div
									className={classNames(
										'col-12',
										'py-2',
										'd-flex justify-content-center',
										'fw-bold fs-3',
										'text-dark',
									)}>
									Hi,{userData?.Name}
								</div>
								<div className='col-12 py-2  transition-base cursor-pointer bg-light-hover text-center'>
									<Button className='border border-dark'>
										{' '}
										<Icon
											icon='ElectricalServices'
											size='2x'
											color='warning'
										/>{' '}
										Manage your filez account
									</Button>
								</div>
							</div>

							<div
								className={classNames(
									'col-6 py-2 transition-base cursor-pointer bg-light-hover',
									'border-bottom',
									'border-end',
									{ 'border-dark': darkModeStatus },
								)}>
								<div className='d-flex flex-column align-items-center justify-content-center'>
									<Icon icon='Add' size='3x' color='success' />
									<span>Add Account</span>
								</div>
							</div>
							<div
								onClick={SignOut}
								className={classNames(
									'col-6 py-2 transition-base cursor-pointer bg-light-hover',
									'border-bottom',
									{ 'border-dark': darkModeStatus },
								)}>
								<div className='d-flex flex-column align-items-center justify-content-center'>
									<Icon icon='Logout' size='3x' color='danger' />
									<span>Sign Out</span>
								</div>
							</div>

							<div
								className={classNames(
									'col-6 py-1 transition-base cursor-pointer bg-light-hover',
									'border-bottom',
									{ 'border-dark': darkModeStatus },
								)}>
								<div className='d-flex flex-column align-items-center justify-content-center'>
									<small className='text-muted'>Privacy Policy</small>
								</div>
							</div>
							<div
								className={classNames(
									'col-6 py-1 transition-base cursor-pointer bg-light-hover',
									'border-bottom',
									{ 'border-dark': darkModeStatus },
								)}>
								<div className='d-flex flex-column align-items-center justify-content-center'>
									<small className='text-muted'>Terms of Service</small>
								</div>
							</div>
						</div>
					</DropdownMenu>
				</Dropdown>
			</div>
		</>
	);
};

export default CommonHeaderChat;
