import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createProfile, createUsera, deleteUser, getAlluser, searchUser, sortUser, updateUser, getbyiduser, searchUserbyAge, RemoveImageintoGallery, uploadGalleryImage, loginUser } from "../Api/UserManagement";

interface User {
  _id: string;
  name: string;
  password: string;
  email: string;
  DOB: string;
  phone_number: string;
  mode: string;
  iAm: "Male" | "Female";
  looking: "Male" | "Female";
  marial: "Single" | "Married";
  status: "active" | "inactive";
  city: string;
}

interface UserState {
  users: User[];
  user: any;
  page: any;
  loading: boolean;
  error: null | string;
}

const initialState: UserState = {
  users: [],
  user: [],
  page: 1,
  loading: false,
  error: null,
};

const AsyncFunctionThunk = (name: string, apiFunction: any) => {
  return createAsyncThunk<User[], any>(
    `user/${name}`,
    async (data: any) => {
      try {
        const response: AxiosResponse<any, any> = await apiFunction(data);
        return response.data;
      } catch (error) {
        console.error(`Failed to ${name}:`, error);
        throw error;
      }
    }
  );
};

export const createUser = AsyncFunctionThunk('createUser', createUsera);
export const LoginUser = AsyncFunctionThunk('LoginUser', loginUser);

export const uploadGalleryImages = AsyncFunctionThunk('uploadGalleryImages', uploadGalleryImage);
export const fetchUser = AsyncFunctionThunk('fetchUser', getAlluser);
export const getbyidusers = AsyncFunctionThunk('getbyidusers', getbyiduser);
export const fetchdeleteUser = AsyncFunctionThunk('fetchdeleteUser', deleteUser);
export const fetchupdateUser = AsyncFunctionThunk('fetchupdateUser', updateUser);
export const RemoveImageintoGallerys = AsyncFunctionThunk('RemoveImageintoGallerys', RemoveImageintoGallery);
export const fetchsearchUser = AsyncFunctionThunk('fetchsearchUser', searchUser);
export const searchUserbyAges = AsyncFunctionThunk('searchUserbyAges', searchUserbyAge);
export const fetchsortUser = AsyncFunctionThunk('fetchsortUser', sortUser);

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<any>) => {
      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.rejected, (state) => {
        state.loading = false;
        state.error = "error";
      })
      .addCase(LoginUser.fulfilled, (state,action) => {
        state.loading = false;
        state.user = action.payload;

      })
      .addCase(LoginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginUser.rejected, (state) => {
        state.loading = false;
        state.error = "error";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(getbyidusers.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchdeleteUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchsearchUser.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(searchUserbyAges.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(fetchsortUser.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(RemoveImageintoGallerys.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export const { setPage } = userSlice.actions;

export default userSlice.reducer;
