import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/FilezLogo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import { ModeId } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { LoginUser, createUser } from '../../../redux/Slice/UserManagement_slice';
import { createForgotSlice, createOTPverfiySlice, updatePasswordSlice } from '../../../redux/Slice/AuthSlice';
import showNotification from '../../../components/extras/showNotification';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Forgot password </div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const ForgotPassword: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();
	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [updatePassword, setUpdatePassword] = useState<boolean>(false);

	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);
	const dispatch = useDispatch();
    const state = useSelector((statee:any)=>statee.auth)
  console.log(state?.forgotUser?.token)
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: '',
			otp: '',
			mode: ModeId,
			Name: '',
			phone_number: '',
			status: 'active',
            newPassword:'',
            token:state?.forgotUser?.token
		},
		validate: (values) => {
			// const errors: { email?: string; password?: string } = {};

			// if (!values.email) {
			// 	errors.email = 'Required';
			// }

			// if (!values.password) {
			// 	errors.password = 'Required';
			// }

			// return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
		 
            
            const res =  await dispatch(createOTPverfiySlice({ values }) as any);
            console.log(res)
            if(res?.meta?.requestStatus === "fulfilled"){

                setUpdatePassword(true)
            }else{
                showNotification(
                    <span className='d-flex align-items-center'>
                        <span>OTP Invalid</span>
                    </span>,
                    <div role='presentation'>
                        <p>OTP doesn't exist</p>
                    </div>,
                );
            }
    // setIsLoading(true);
	// 	setTimeout(async() => {
	// 	res?.meta?.requestStatus === "fulfilled"  ?	setSignInPassword(true):setSignInPassword(false)
    // !signInPassword &&    showNotification(
    //         <span className='d-flex align-items-center'>
               
    //             <span>Email Invalid</span>
    //         </span>,
    //         <div  role='presentation'>
    //             <p>Email doesn't exist</p>
    //         </div>,
    //     );
    // console.log(res)
	// 		setIsLoading(false);
	// 	}, 1000);
			// if (singUpStatus) {
			// 	await dispatch(createUser({ values }) as any);
			// } else {
			// 	const res = await dispatch(LoginUser({ values }) as any);

			// 	if (res.payload) {
			// 		await localStorage.setItem('Filez_authUsername', res.payload.Name);


			// 		handleOnClick();
			// 	}
			// }
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const handleContinue = async () => {
        setIsLoading(true);
        setTimeout(async () => {
            const res = await dispatch(createForgotSlice({ email: formik.values.email })as any);
            if (res?.meta?.requestStatus === "fulfilled") {
                setSignInPassword(true);
            } else {
                setSignInPassword(false);
                showNotification(
                    <span className='d-flex align-items-center'>
                        <span>Email Invalid</span>
                    </span>,
                    <div role='presentation'>
                        <p>Email doesn't exist</p>
                    </div>,
                );
            }
            setIsLoading(false);
        }, 1000);
    };
    


    const handleUpdatePassword = async () => {
        console.log({ email: formik.values.email, newPassword: formik.values.newPassword });
        const res = await dispatch(updatePasswordSlice({ email: formik.values.email, newPassword: formik.values.newPassword })as any) ;
        if (res?.payload?.isSuccess) {
            await localStorage.setItem('Filez_authUsername', 'Name');
            handleOnClick();
        }
    }
    

	return (
		<PageWrapper
			isProtected={false}
			title="Forgot Password"
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center backgroundImage'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'><Logo width={200} /></div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col-12'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Forgot Password
											</Button>
										</div>
									
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className='row g-4'>
									
										<>
											<div className='col-12'>
												<FormGroup
													id='email'
													isFloating
													label='Your email or username'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={formik.values.email}
														isTouched={formik.touched.email}
														invalidFeedback={formik.errors.email}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {formik.values.email}.
													</div>
												)}
											{!updatePassword ?
                                            	<FormGroup
													id='otp'
													isFloating
													label='otp'
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='text'
														autoComplete='otp'
														value={formik.values.otp}
														isTouched={formik.touched.otp}
														invalidFeedback={formik.errors.otp}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>

                                               :
                                               <>
                                               	<div className='col-12  py-3'>

                                               <FormGroup
													id='email'
													isFloating
													label='Your email or username'
													// className={classNames({
													// 	'd-none': signInPassword,
													// })}
                                                    >
													<Input
														autoComplete='username'
														value={formik.values.email}
														isTouched={formik.touched.email}
														invalidFeedback={formik.errors.email}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
                                                </div>
                                                <div className='col-12'>
												<FormGroup id='newPassword' isFloating label='Your newPassword'>
													<Input
                                                    type='password'
														autoComplete='newPassword'
														value={formik.values.newPassword}
														isTouched={formik.touched.newPassword}
														invalidFeedback={formik.errors.newPassword}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
                                                </div>
                                               </>
                                                
                                                }
										
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.email}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Continue
													</Button>
												) : (
                                                    <>
													<Button
														color='warning'
														className={`w-100 py-3 ${updatePassword ? "d-none":"d-block"}`}
														onClick={formik.handleSubmit}>
                                                            {state?.loading && (
															<Spinner isSmall inButton isGrow />
														)}
														Login
													</Button>
                                                    <Button
                                                    color='warning'
                                                    className={`w-100 py-3 ${!updatePassword ? "d-none":"d-block"}`}
                                                    onClick={handleUpdatePassword}
                                                    >
                                                        {state?.loading && (
                                                        <Spinner isSmall inButton isGrow />
                                                    )}
                                                    Update New Password
                                                </Button>
                                                </>

												)}
											</div>
										</>
									
{!signInPassword && (
										<>
											<div className='col-12 mt-3 text-right text-muted ' style={{cursor:'pointer'}}>
											<Link  to='/auth-pages/login'>Login</Link>	
											</div>
										
										</>
									)}
									{/* BEGIN :: Social Login */}
									
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
ForgotPassword.propTypes = {
	isSignUp: PropTypes.bool,
};
ForgotPassword.defaultProps = {
	isSignUp: false,
};

export default ForgotPassword;
