import React, { ReactNode, useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {
	StorageMenu,
	componentPagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	gettingStartedPagesMenu,
	pageLayoutTypesPagesMenu,
} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Hand from '../../../assets/img/hand.png';
import HandWebp from '../../../assets/img/hand.webp';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { AddOption } from '../../Filez/home/helper';
import Progress from '../../../components/bootstrap/Progress';
import AddFolder from '../../../components/FilezComponents/Home/AddFolder';
import { useSelector,useDispatch } from 'react-redux'
import { createFlie, createFlieSlice, getallFileSlice } from '../../../redux/Slice/FileSlice.tsx/FileSlice';
import { ModeId } from '../../../utils';

const DefaultAside = () => {
	const dispatch = useDispatch()
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('Filez_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();
	const handleFileChange = async(event: any) => {
		if (event.target.files && event.target.files[0]) {
			const fileName: string = event.target.files[0].name;
			
			
	
	const res= 		await dispatch(createFlie({
				file_name:fileName,
				mode: ModeId,
				is_video: false,
				is_image: false,
				is_doc: false,
				is_deleted: false,
			}) as any);
			console.log(res,"rea")
			await dispatch(createFlieSlice({
				id:res?.payload?._id,
				file:event.target.files[0]
			})as any)
			dispatch(getallFileSlice(ModeId)as any)
		} else {
			console.error("No file selected or invalid file object.");
		}
	
	};
	
	const[Modal,setModal]=useState(false)
	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>

			<AsideBody>
				<div className='row justify-content-start'>
					<div className='ms-1 col-8 text-center'>
						<Dropdown>
							<DropdownToggle>
								<Button
									style={{ border: '2px rgb(35 35 44) solid' }}
									className='w-100 AddMenu  text-light text-center'
									icon='Add'
									isLight>
									New
								</Button>
							</DropdownToggle>
							<DropdownMenu
								isAlignmentEnd
								size='md'
								style={{ background: '#05050b', zIndex: '9999' }}>
									<DropdownItem  className='px-2'>
											<div
												style={{
													borderBottom: '1px gray  solid',
													position: 'relative',
												}}
												className='AddMenu '>
												<Button className='text-light' onClick={()=>setModal(!Modal)} isLight>
													+ ADD FOLDER
												</Button>
											
											</div>
										</DropdownItem>
								{AddOption.map((items, index) => {
									return (
										<DropdownItem key={index} className='px-2'>
											<div
												style={{
													borderBottom: '1px gray  solid',
													position: 'relative',
												}}
												className='AddMenu '>
												<Button className='text-light' isLight>
													+ {items?.name}
												</Button>
												<div>
													<input
														type='file'
														style={{
															position: 'absolute',
															top: '0px',
															left: '0px',
															opacity: 0,
															zIndex: '999999',
															cursor: 'pointer',
														}}
														onChange={handleFileChange}
													/>
												</div>
											</div>
										</DropdownItem>
									);
								})}
							</DropdownMenu>
						</Dropdown>
					</div>
				</div>
				{!doc && (
					<>
						<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
						<NavigationLine />
						{/* <Navigation menu={demoPagesMenu} id='aside-demo-pages' />
						<NavigationLine />
						<Navigation menu={pageLayoutTypesPagesMenu} id='aside-menu' /> */}
					</>
				)}

				{!doc && (
					<>
						<Navigation menu={StorageMenu} id='aside-docMenu' />
					</>
				)}

				<div className='px-3'>
					<Progress value={35} />
					<div className='text-center'>
						<p>6.65 GB of 15 GB used</p>
					</div>
					<div className='text-center'>
						<Button className='text-light AddMenu border border-light' isLight>
							Get more storage
						</Button>
					</div>
				</div>

				{/* {asideStatus && doc && (
					<Card className='m-3 '>
						<CardBody className='pt-0'>
							<img srcSet={HandWebp} src={Hand} alt='Hand' width={130} height={130} />
							<p
								className={classNames('h4', {
									'text-dark': !darkModeStatus,
									'text-light': darkModeStatus,
								})}>
								{t('Everything is ready!') as ReactNode}
							</p>
							<Button
								color='info'
								isLight
								className='w-100'
								onClick={() => {
									localStorage.setItem('Filez_asideDocStatus', 'false');
									setDoc(false);
								}}>
								{t('Demo Pages') as ReactNode}
							</Button>
						</CardBody>
					</Card>
				)} */}
			</AsideBody>
			<AsideFoot>
				<></>
				{/* <nav aria-label='aside-bottom-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								localStorage.setItem('Filez_asideDocStatus', String(!doc));
								setDoc(!doc);
							}}
							data-tour='documentation'>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={doc ? 'ToggleOn' : 'ToggleOff'}
										color={doc ? 'success' : undefined}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{t('menu:Documentation') as ReactNode}
									</span>
								</span>
								<span className='navigation-link-extra'>
									<Icon
										icon='Circle'
										className={classNames(
											'navigation-notification',
											'text-success',
											'animate__animated animate__heartBeat animate__infinite animate__slower',
										)}
									/>
								</span>
							</span>
						</div>
					</div>
				</nav> */}
				{/* <User /> */}
			</AsideFoot>
			<AddFolder setIsOpen={setModal} isOpen={Modal} id='0'/>
		</Aside>
	);
};

export default DefaultAside;
