import axios from "axios"
import { BaseURl } from "../../../utils"

export const getAllTrash = async (id: any) => {
    try {
        const response1: any = await axios.get(`${BaseURl}folders/getAllTrash/${id}?page_no=1&page_size=100`);
        const response2: any = await axios.get(`${BaseURl}file/getAllTrash/${id}?page_no=1&page_size=100`);

        const combinedResponse = [...response1.data.data, ...response2.data.data];

        const sortedCombinedResponse = combinedResponse.sort((a: any, b: any) => {
           console.log(new Date(b.created_at).getTime(),"tt")
            return new Date(b.created_at).getTime() - new Date(a.updated_at).getTime()
        }
        );

        return sortedCombinedResponse;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

// restore

export const restoreTrash =async(data:any)=>{
    const {id,Name}=data
    console.log(Name,"Tr")
    const api = Name ==="Folder"? "folders/restoreFolder/":"file/restoreFile"
    try {
        const response:any  =await axios.put(`${BaseURl}${api}/${id}`)
       
        return response
    } catch (error) {
        console.log(error) 
        throw error;   
    }
}

export const deleteTrash =async(data:any)=>{
    const {id,Name}=data
    console.log(Name,id,"Tr")
    const api = Name ==="Name"? "folders/deleteFolderByIdPermanently":"file/deleteFileByIdPermanently"
    try {
        const response:any  =await axios.delete(`${BaseURl}${api}/${id}`)
       
        return response
    } catch (error) {
        console.log(error) 
        throw error;   
    }
}