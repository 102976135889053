import axios from "axios"
import { BaseURl } from "../../../utils"

export const getAllStarred = async (id: any) => {
    try {
        const response1: any = await axios.get(`${BaseURl}folders/getallbystarred/${id}?page_no=1&page_size=100`);
        const response2: any = await axios.get(`${BaseURl}file/getallstarredfile/${id}?page_no=1&page_size=100`);

        const combinedResponse = [...response1.data.data, ...response2.data.data];

        // const sortedCombinedResponse = combinedResponse.sort((a: any, b: any) => {
        //    console.log(new Date(b.created_at).getTime(),"tt")
        //     return new Date(b.created_at).getTime() - new Date(a.updated_at).getTime()
        // }
        // );

        return combinedResponse;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
