import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteTrash, getAllTrash, restoreTrash } from "../../Api/Trash/Trash";
const initialState :any={
    Trash:[],
    loading:false,
    error:null
}
// get all trash
export const getallTrashSlice = createAsyncThunk('Trash/getallTrashSlice', async (data: any) => {
	try {
		const response = await getAllTrash(data);
		return response;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});

// restore
export const restoreTrashSlice = createAsyncThunk('Trash/restoreTrashSlice', async (data: any) => {
	try {
		const response = await restoreTrash(data);
		return response.data.data;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});
export const deleteTrashSlice = createAsyncThunk('Trash/deleteTrashSlice', async (data: any) => {
	try {
		const response = await deleteTrash(data);
		return response.data.data;
	} catch (error) {
		console.error('Error creating user:', error);
		throw error;
	}
});
const TrashSlice = createSlice({
    name:'trashSlice',
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(getallTrashSlice.fulfilled,(state,action)=>{
           state.Trash= action.payload
           state.loading = false;

        })
        .addCase(getallTrashSlice.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getallTrashSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = 'error';
        })
        // restore restoreTrashSlice
        .addCase(restoreTrashSlice.fulfilled,(state,action)=>{
            console.log(action.payload,"return")
            const data = state.Trash.filter(( item:any)=>{
                return item._id !==action.payload._id
            })
            state.Trash=data
           state.loading = false;

         })
         .addCase(restoreTrashSlice.pending, (state, action) => {
             state.loading = true;
         })
         .addCase(restoreTrashSlice.rejected, (state, action) => {
             state.loading = false;
             state.error = 'error';
         })


        //  detle
        .addCase(deleteTrashSlice.fulfilled,(state,action)=>{
            state.loading = false;
 
         })
         .addCase(deleteTrashSlice.pending, (state, action) => {
             state.loading = true;
         })
         .addCase(deleteTrashSlice.rejected, (state, action) => {
             state.loading = false;
             state.error = 'error';
         })
    },
})

export default TrashSlice.reducer;
