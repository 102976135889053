import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import data from '../../../common/data/dummyCustomerData';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Label from '../../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import PAYMENTS from '../../../common/data/enumPaymentMethod';
import { useSelector,useDispatch } from 'react-redux'
import { createFolderSlice } from '../../../redux/Slice/FolderSidebar/FolderSideBar';
import { ModeId } from '../../../utils';

interface IAddFolderProps {
	id: string;
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
}
const AddFolder: FC<IAddFolderProps> = ({ id, isOpen, setIsOpen }) => {
	const itemData = id ? data.filter((item) => item.id.toString() === id.toString()) : {};
	const item = id && Array.isArray(itemData) ? itemData[0] : {};
    const dispatch = useDispatch()
	const formik = useFormik({
		initialValues: {
            mode:ModeId,
            // user:'',
			Name: '',
            description:'',
            status:'active',
            metadata:''
            
	
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values:any,{resetForm}) => {
			setIsOpen(false);
            console.log(values);
            
            dispatch(createFolderSlice(values)as any)
            resetForm()
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Added Successfully</span>
				</span>,
				'Folder has been Added successfully',
			);
		},
	});
	const handleKeyDown= (e:any)=>{
		if(e.key === 'Enter'){
			formik.handleSubmit()
		}

	}

	if (id || id === '0') {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} size='sm' titleId={id.toString()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{item?.name || 'ADD FOLDER'}</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-2'>
						<FormGroup id='Name' label='Name' className='col-12'>
							<Input onChange={formik.handleChange} value={formik.values.Name} onKeyDown={handleKeyDown}/>
						</FormGroup>
						
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button color='info' onClick={formik.handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
	return null;
};
AddFolder.propTypes = {
	id: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default AddFolder;
